import React from "react"
import SEO from "../components/seo"
import axios from "axios";
import "../styles/contact.scss"
import {Link} from "gatsby";
import {Helmet} from "react-helmet";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import toaster from "toasted-notes";
import 'toasted-notes/src/styles.css';

const allowedTypes = /\bdoc\b|\bdocx\b|\bpdf\b|\bzip\b|\brar\b/;

export default class Contact extends React.Component {
    state = {
        contactType: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        selectedFile: '',
        outPutText: ''

    }
    handleChange = (e) => {
        switch (e.target.name) {
            case 'selectedFile':
                this.setState({ selectedFile: e.target.files[0] });
                this.setState({ outPutText: e.target.files[0].name});
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }
    resetInput = () => {
        this.fileInput.value = '';
    }
    handleSubmit = (formValues) => {
        console.log('state', this.state);
        const { contactType, name, phone, email, message } = formValues;
        const { selectedFile } = this.state;

        let formData = new FormData();
        formData.append('contactType', contactType);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('selectedFile', selectedFile);
        axios.post("https://backend.s7codedesign.com/contact", formData)

            .then(function (response) {
                console.log("response", response);
                if (response.status === 200) {
                    toaster.notify("Message has been successfully sent. We will answer you as soon as possible.", {
                        duration: 3000,
                        position: "top-right"
                      });
                }

            })
            .catch(function (error) {
                toaster.notify("Message sending fail. Our develop team is working to fix a problem.", {
                    duration: 3000,
                    position: "top-right"
                  });
            })

    }
    render() {
        return (
            <div>
                <Helmet defer={false}>
                    <meta charSet="utf-8" />
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"/>
                    <link href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,900&amp;subset=latin-ext" rel="stylesheet"/>
                </Helmet>

                <div className="contact-main">
                    <SEO title="Contact" keywords={[`contact`, `mail`, `facebook`, `instagram`, `linkedin`]}
                    description="Contact us if you looking for creative solutions and young professional, or if you
want to become one of them-so join us."
                    />

                    <div className="row justify-content-center">
                        <div className="col-10 mt-sm-5 mt-2">
                            <div className="back_btn">
                                <Link to="/"><span className="icon-left-open-big"> </span>BACK TO HOME</Link>
                            </div>
                        </div>
                    </div>
                    <div className="container h-100">
                        <div className="row h-100 align-content-sm-center mt-3 mt-sm-0">
                            <div className="col-12">
                                <div className="contact-form">
                                    <h1>Let's make something</h1>
                                    <Formik
                                        initialValues={{contactType: 'Official website contact form', name: '', email: '', phone: '', message:'', selectedFile: '' }}
                                        validate={values => {
                                            let errors = {};


                                            // email
                                            if (!values.email) {
                                                errors.email = 'Email field is required';
                                            } else if (
                                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                            ) {
                                                errors.email = 'Invalid email address';
                                            }
                                            // name
                                            if (!values.name) {
                                                errors.name = 'Name field is required';
                                            } else if (
                                                !/^[A-ZšđčćžŠĐČŽĆ ]{2,}$/i.test(values.name)
                                            ) {
                                                errors.name = 'Name must contain at least 2 letters.';
                                            }
                                            // phone
                                            if (values.phone && !/^[0-9._%+-]{6,}$/i.test(values.phone)) {
                                                errors.phone = 'Invalid phone number format.';
                                            }
                                            // message
                                            if (!values.message) {
                                                errors.message = 'Message field is required';
                                            } else if (
                                                !/^[A-ZšđčćžŠĐČŽĆ 0-9.,;:" '_%+-]{10,}$/i.test(values.message)
                                            ) {
                                                errors.message = 'Please fill up message field';
                                            }
                                            // files
                                            const {selectedFile} = this.state;

                                             if (
                                                selectedFile && !selectedFile.name.split('.')[1].match(allowedTypes)
                                            ) {
                                                errors.selectedFile = 'Wrong file format.';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            setTimeout(() => {
                                                setSubmitting(false);
                                                this.handleSubmit(values);
                                                resetForm();
                                                this.setState({outPutText: ''})
                                            }, 400);
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <Field type="hidden" name="contactType" className="invisible-field"/>
                                                <div className="form-row">
                                                    <div className="form-group w-100 position-relative">
                                                        <label>NAME</label>
                                                        <Field type="text" name="name"  placeholder="" autoFocus/>
                                                        <ErrorMessage name="name" component="span" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6 col-12 position-relative">
                                                        <label>PHONE</label>
                                                        <Field type="text" name="phone"  placeholder=""/>
                                                        <ErrorMessage name="phone" component="span" className="error-message" />
                                                    </div>
                                                    <div className="form-group col-md-6 col-12 position-relative">
                                                        <label>EMAIL</label>
                                                        <Field type="email" name="email" placeholder=""/>
                                                        <ErrorMessage name="email" component="span" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group w-100 position-relative">
                                                        <label>WHAT CAN WE DO FOR YOU</label>
                                                        <Field name="message" component="textarea" rows="1" placeholder="" />
                                                        <ErrorMessage name="message" component="span" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="attach-file position-relative">
                                                        <input id="file" name="selectedFile" type="file" onChange={(event) =>{this.handleChange(event)}} />
                                                        <label for="file" className="icon-attach custom-file-label text-uppercase"> attach file (doc, docx, pdf, zip, rar)</label>
                                                        {/* <ErrorMessage name="selectedFile" component="span" className="error-message" /> */}
                                                        { this.state.selectedFile && !this.state.selectedFile.name.split('.')[1].match(allowedTypes) && (<div className="contactCustomErrorMsg">Wrong file format.</div>)}
                                                        { this.state.outPutText && (<div className="contactFileNameText">{this.state.outPutText}</div>)}
                                                    </div>
                                                </div>
                                                <div className="form-row justify-content-end">
                                                    <button className="btn" type="submit" disabled={isSubmitting}>SEND</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

